<template>
  <div class="px-2">
    <b-card class="mb-1">
      <div class="d-flex justify-content-between">
        <h2>{{ $t('operator_manage.detail_title') }}</h2>
        <!-- {{etsDetails._id}}
        {{etsDetails.emailPromoteur}} -->

        <ButtonArchiverInscription
          v-if="!isOperator && etsDetails"
          :operateur-id="etsDetails.id"
          :admin-etablissement-email="etsDetails.emailPromoteur"
          redirect-route="operateurs-list"
        />
      </div>
      <!-- <b-link class="brand-logo">
          <AppLogo />
        </b-link> -->
    </b-card>
    <h1 v-if="!isFetchingEtsDetails && !etsDetails">
      {{ $t('operator_manage.empty_operator') }}
    </h1>
    <content-loader
      v-if="isFetchingEtsDetails"
      view-box="0 0 400 460"
      :speed="2"
      primary-color="#f3f3f3"
      secondary-color="#ecebeb"
    >
      <circle cx="29" cy="30" r="17" />
      <rect x="58" y="18" rx="2" ry="2" width="140" height="10" />
      <rect x="58" y="34" rx="2" ry="2" width="140" height="10" />
      <rect x="-7" y="60" rx="2" ry="2" width="408" height="132" />
    </content-loader>
    <b-card v-if="etsDetails" class="mb-2">
      <b-row>
        <b-col v-if="isOperator" sm="12">
          <!-- <h4>Statut du traitement des informations</h4> -->
          <div class="d-flex justify-content-end">
            <!-- <b-dropdown
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              split
              :text="currentStatus"
              :variant="currentColor"
              left
            >
              <b-dropdown-item @click="onEtsStatusUpdate('false')">
                Non traité
              </b-dropdown-item>
              <b-dropdown-item @click="onEtsStatusUpdate('en cours')">
                Traitement en cours
              </b-dropdown-item>
              <b-dropdown-item @click="onEtsStatusUpdate('true')">
                Validé
              </b-dropdown-item>
            </b-dropdown> -->

            <b-button
              class="mr-1"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="updateData()"
            >
              <i class="las la-edit mr-25" v-if="!isValdatingEts" />
              <b-spinner v-else small class="mr-25" />
              {{ $t('button.edit_form') }}
            </b-button>

            <!--  <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-sendMail
                variant="outline-primary"
              >
                <i class="las la-paper-plane" />
                {{ $t('button.send_mail') }}
              </b-button>
            </div> -->
          </div>
          <!-- <div
            v-if="
              $can('validate', 'operateurs') &&
              $can('reject', 'operateurs')
            "
            class="d-flex gap-10 flex-wrap-align-items-center mt-2"
          >
            <ButtonRejectEtablissement
              v-if="!etsDetails.isVerified"
              :ets-details="etsDetails"
              @onEtsRejectionSuccess="onEtsRejectionSuccess"
            />
            <ButtonValidateEtablissement
              v-if="!etsDetails.isVerified"
              :input-datas="etsDetails"
              @onEtsValidationSuccess="onEtsValidationSuccess"
            />
          </div> -->
          <hr />
        </b-col>
      </b-row>
      <b-row>
        <!-- etablissement -->
        <b-col sm="6">
          <h3 class="my-2">{{ $t('table_columns.company') }}</h3>
          <p>
            <strong>{{ $t('operator_manage.detail_label_ifu') }} : </strong>
            <b-form-input v-if="etsDetails.ifu" v-model="etsDetails.ifu" />
            <b-form-input v-else v-model="newIFU" />
          </p>

          <p>
            <strong>RCCM: </strong>
            <b-form-input v-if="etsDetails.rccm" v-model="etsDetails.rccm" />
            <b-form-input v-else v-model="newRCCM" />
          </p>

          <p>
            <strong>{{ $t('table_columns.lastname') }} : </strong>
            <b-form-input v-model="etsDetails.nom_etablissement" />
          </p>

          <div v-if="etsDetails && etsDetails.secteur">
            <p>
              <strong>{{ $t('table_columns.sector') }} : </strong>
              <b-form-input v-model="etsDetails.secteur.title" disabled />
            </p>
          </div>

          <p>
            <strong>{{ $t('table_columns.address') }} : </strong>
            <b-form-input v-model="etsDetails.adresse_etablissement" />
          </p>

          <p>
            <strong>{{ $t('table_columns.phone') }} : </strong>
            <b-form-input
              v-model="etsDetails.telephone_etablissement"
              v-phonenumber-field
            />
          </p>

          <p>
            <strong>{{ $t('table_columns.email') }} : </strong>
            <b-form-input v-model="etsDetails.email_etablissement" />
          </p>

          <p>
            <strong>{{ $t('table_columns.departement') }} : </strong>
            <b-form-input v-model="etsDetails.departement" />
          </p>

          <p>
            <strong>{{ $t('table_columns.commune') }} : </strong>
            <b-form-input v-model="etsDetails.commune" />
          </p>

          <p>
            <strong>{{ $t('table_columns.district') }} : </strong>
            <b-form-input v-model="etsDetails.arrondissement" />
          </p>

          <p>
            <strong>{{ $t('table_columns.village') }} : </strong>
            <b-form-input v-model="etsDetails.quartier" />
          </p>
        </b-col>
        <!-- promoteur -->
        <b-col>
          <h3 class="my-2">{{ $t('table_columns.promoter') }}</h3>

          <p>
            <strong
              >{{ $t('operator_manage.detail_label_lastname_promotor') }} :
            </strong>
            <b-form-input v-model="etsDetails.nom_promoteur" />
          </p>

          <p>
            <strong
              >{{ $t('operator_manage.detail_label_firstname_promotor') }} :
            </strong>
            <b-form-input v-model="etsDetails.prenoms_promoteur" />
          </p>

          <p>
            <strong
              >{{ $t('operator_manage.detail_label_phone_promotor') }} :
            </strong>
            <b-form-input
              v-model="etsDetails.telephone_promoteur"
              v-phonenumber-field
            />
          </p>

          <p>
            <strong
              >{{ $t('operator_manage.detail_label_email_promotor') }} :
            </strong>
            <b-form-input v-model="etsDetails.email_promoteur" />
          </p>

          <p>
            <strong
              >{{ $t('operator_manage.detail_label_npi_promotor') }} :
            </strong>
            <b-form-input v-model="etsDetails.npicni_promoteur" />
          </p>

          <p>
            <strong>{{ $t('table_columns.gender') }} : </strong>
            <v-select
              id="profils"
              v-model="etsDetails.sexe_promoteur"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="genders"
              :reduce="(option) => option.value"
            />
          </p>

          <!--           <p >
            <strong>{{ $t('table_columns.nationality') }} : </strong>
            <b-form-input v-model="etsDetails.nationality_promoteur" />
          </p>
          <p v-else>
            <strong>{{ $t('table_columns.nationality') }} : </strong>
            {{ etsDetails.nationality_promoteur }}
          </p> -->
          <hr class="my-1" />

          <!-- <h3>Pièces jointes</h3>
          <DetailsPiecesJointes :files="etsDetails.fichiers" /> -->
          <h3 class="my-2">{{ $t('operator_manage.detail_legal_rep') }}</h3>
          <p>
            <strong>{{ $t('table_columns.lastname') }} : </strong>
            <b-form-input v-model="etsDetails.nom_representant" />
          </p>

          <p>
            <strong>{{ $t('table_columns.firstname') }} : </strong>
            <b-form-input v-model="etsDetails.prenoms_representant" />
          </p>

          <p>
            <strong>{{ $t('table_columns.gender') }} : </strong>
            <v-select
              id="profils"
              v-model="etsDetails.sexe_representant"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="genders"
              :reduce="(option) => option.value"
            />
          </p>

          <p>
            <strong>{{ $t('table_columns.nationality') }} : </strong>
            <b-form-input v-model="etsDetails.nationalite_representant" />
          </p>
        </b-col>
      </b-row>

      <!-- <b-row>
        <b-col>
          <h3>Pièces jointes</h3>
          <div class="img-ctn">
            <div class="img" />
            <div class="img" />
            <div class="img" />
            <div class="file" />
          </div>
        </b-col>
      </b-row> -->

      <!-- <hr /> -->
      <!-- <b-row v-if="isAdminOrAgentMTCA">
        <b-col>
          <h4 class="my-2">Liste des agents</h4>
          <ListAgentsComponent :etablissement-id="operateur_id" />
        </b-col>
      </b-row> -->
    </b-card>

    <!-- modal send mail-->
    <b-modal
      id="modal-sendMail"
      cancel-variant="outline-secondary"
      ok-title="Envoyer"
      cancel-title="Fermer"
      centered
      title="Mail au promoteur d'établissement"
      @ok="readySendMail()"
    >
      <b-form>
        <b-form-group>
          <label for="email">{{ $t('table_columns.email') }}:</label>
          <b-form-input
            v-model="newEmail.to"
            type="email"
            placeholder="Email du promoteur"
          />
        </b-form-group>
        <b-form-group>
          <label for="email">{{ $t('table_columns.subject') }}:</label>
          <b-form-input
            v-model="newEmail.subject"
            type="email"
            placeholder="Sujet du mail"
          />
        </b-form-group>
        <b-form-group>
          <label for="password">{{ $t('table_columns.message') }}</label>
          <b-form-textarea
            v-model="newEmail.htmlText"
            placeholder="Contenu du mail"
            rows="3"
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BFormFile,
  BSpinner,
  BBadge,
  BDropdown,
  BDropdownItem,
  VBModal,
  BModal,
  BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { mapGetters, mapActions } from 'vuex'
import vSelect from 'vue-select'
import AppLogo from '@/components/AppLogo.vue'
import RecaptchaWidget from '@/components/RecaptchaWidget.vue'
import localstorageService from '@/services/localstorage/localstorage.service'
import { ContentLoader } from 'vue-content-loader'
import DetailsPiecesJointes from '@/views/apps/utilisateurs/operateurs/components/DetailsPiecesJointes.vue'
import ButtonArchiverInscription from '@/views/apps/utilisateurs/operateurs/components/ButtonArchiverInscription.vue'

import utilsService from '@/services/utils/utils.service'
import etablissementsStroreModule from '@/store/etablissements'
import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'
// import ListAgentsComponent from '@/views/apps/utilisateurs/agents/components/ListAgentsComponent.vue'
// import ButtonRejectEtablissement from './ButtonRejectEtablissement.vue'
// import ButtonValidateEtablissement from './ButtonValidateEtablissement.vue'

// store module and vuex utilities

export default {
  components: {
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BFormFile,
    // validations
    AppLogo,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    RecaptchaWidget,
    BSpinner,
    BRow,
    BCol,
    // ListAgentsComponent,
    BBadge,
    BDropdown,
    BDropdownItem,
    BModal,
    BFormTextarea,

    ToastificationContent,
    // ButtonRejectEtablissement,
    // ButtonValidateEtablissement,
    ContentLoader,
    DetailsPiecesJointes,
    ButtonArchiverInscription,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  setup() {
    const requiredStoreModules = [
      { path: 'etablissements', module: etablissementsStroreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)

    const { isOperator } = utilsService.currentUserUtils()

    return {
      isOperator,
      requiredStoreModules,
    }
  },
  data() {
    return {
      isFetchingEtsDetails: false,
      etsDetails: null,
      operateur_id: localstorageService.getUserData().profile.id,
      currentStatus: null,
      currentColor: null,
      newIFU: '',
      newRCCM: null,
      newNumAgrement: null,

      newEmail: {
        to: null,
        subject: 'Corrections à effectuer',
        htmlText: null,
      },
      genders: [
        {
          value: 'M',
          label: 'Masculin',
        },
        {
          value: 'F',
          label: 'Féminin',
        },
        {
          value: 'A',
          label: 'Autres',
        },
      ],
      isValdatingEts: false,
    }
  },
  computed: {
    isAdminOrAgentMTCA() {
      const { currentUserId, userRole } = utilsService.currentUserUtils()
      return userRole.code === 'agent_mtca' || userRole.code === 'super_admin'
    },
  },

  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        // const { operateur_id } =
        console.log('this.operateur_id =', this.operateur_id)
        this.loadEtabs(this.operateur_id)
      },

      'newEmail.to': {
        handler(old, val) {
          if (this.etsDetails && etsDetails.email_promoteur != null) {
            return (this.newEmail.to = this.etsDetails.email_promoteur)
          }
        },
      },
    },
  },
  beforeDestroy() {
    // unregisterStoreModule(this.requiredStoreModules);
  },

  mounted() {},
  methods: {
    ...mapActions('users', {
      action_getUser: 'getUser',
    }),
    ...mapActions('etablissements', {
      action_getEtablissement: 'getEtablissement',
      createEtablissement: 'createEtablissement',
      action_updateEtablissement: 'updateEtablissement',
      action_getListTypesEtablissement: 'getListTypesEtablissement',
    }),
    ...mapActions('utils', {
      sendMail: 'sendMail',
    }),

    onEtsStatusUpdate(status) {
      this.isValdatingEts = true
      // this.etsDetails = { ...this.etsDetails, isVerified: status }
      this.action_updateEtablissement({
        id: this.etsDetails.id,
        data: { isVerified: status },
      })
        .then((result) => {
          this.loadEtabs(this.operateur_id)

          // this.etsDetails.isVerified = status
          // this.$emit('onEtsValidationSuccess',result)
          this.isValdatingEts = false
          // this.$toast({
          //   component: ToastificationContent,
          //   position: 'top-right',
          //   props: {
          //     title: 'Mise à jour avec succès',
          //     icon: 'CheckIcon',
          //     variant: 'success',
          //   },
          // })
        })
        .catch((err) => {
          this.isValdatingEts = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || 'Une erreur est survenue',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    updateData() {
      this.isValdatingEts = true
      if (this.newIFU) this.etsDetails.ifu = this.newIFU
      if (this.newRCCM) this.etsDetails.rccm = this.newRCCM
      if (this.etsDetails.secteur_id) {
        this.etsDetails.secteur = this.etsDetails.secteur_id
      }
      const id = this.etsDetails.id

      delete this.etsDetails.id
      delete this.etsDetails.created_at
      delete this.etsDetails.updated_at
      delete this.etsDetails.deleted_at

      this.action_updateEtablissement({
        id,
        data: { ...this.etsDetails },
      })
        .then((result) => {
          // console.log("action_updateEtablissement result::: ⭕️", result);
          this.loadEtabs(this.operateur_id)

          this.isValdatingEts = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Donnéee mise à jour avec succès',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch((err) => {
          this.isValdatingEts = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || 'Une erreur est survenue',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    readySendMail() {
      const formData = new FormData()
      formData.append('objectdata', JSON.stringify(this.newEmail))
      this.sendMail(formData)
        .then((res) => {
          if (res.status == 200) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Mail Envoyé',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch((err) => {
          console.log(err)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || 'Une erreur est survenue',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    loadEtabs(operateur_id) {
      this.isFetchingEtsDetails = true
      this.action_getEtablissement(operateur_id)
        .then((res) => {
          if (res.status === 200) {
            this.etsDetails = res.data.data
            console.log('this.etsDetails::: ⭕️', this.etsDetails)
            this.newEmail.to = this.etsDetails.emailPromoteur
            if ((this.etsDetails.ifu = 'null')) this.etsDetails.ifu = ''
            if (this.etsDetails.isVerified == 'false') {
              this.currentStatus = 'Non traité'
              this.currentColor = 'danger'
            }
            if (this.etsDetails.isVerified == 'true') {
              this.currentStatus = 'Validé'
              this.currentColor = 'success'
            }
            if (this.etsDetails.isVerified == 'en cours') {
              this.currentStatus = 'Traitement en cours'
              this.currentColor = 'warning'
            }
            this.loadUser(this.etsDetails.user_id)
          }
        })
        .catch((err) => {
          this.isFetchingEtsDetails = false
        })
    },

    // loadEtabs(operateur_id) {
    //   this.isFetchingEtsDetails = true
    //   this.etsDetails = localstorageService.getUserData().profile
    //   console.log('this.etsDetails::: ⭕️', this.etsDetails)
    //   this.newEmail.to = this.etsDetails.emailPromoteur
    //   if ((this.etsDetails.ifu = 'null')) this.etsDetails.ifu = ''
    //   if (this.etsDetails.isVerified == 'false') {
    //     this.currentStatus = 'Non traité'
    //     this.currentColor = 'danger'
    //   }
    //   if (this.etsDetails.isVerified == 'true') {
    //     this.currentStatus = 'Validé'
    //     this.currentColor = 'success'
    //   }
    //   if (this.etsDetails.isVerified == 'en cours') {
    //     this.currentStatus = 'Traitement en cours'
    //     this.currentColor = 'warning'
    //   }
    //   // this.loadUser(this.etsDetails.id)
    //   // this.action_getEtablissement(operateur_id)
    //   //   .then((res) => {
    //   //     if (res.status === 200) {
    //   //       // this.etsDetails = res.data.data
    //   //       // this.etsDetails = res.data.data
    //   //       // console.log('this.etsDetails::: ⭕️', this.etsDetails)
    //   //       // this.newEmail.to = this.etsDetails.emailPromoteur
    //   //       // if ((this.etsDetails.ifu = 'null')) this.etsDetails.ifu = ''
    //   //       // if (this.etsDetails.isVerified == 'false') {
    //   //       //   this.currentStatus = 'Non traité'
    //   //       //   this.currentColor = 'danger'
    //   //       // }
    //   //       // if (this.etsDetails.isVerified == 'true') {
    //   //       //   this.currentStatus = 'Validé'
    //   //       //   this.currentColor = 'success'
    //   //       // }
    //   //       // if (this.etsDetails.isVerified == 'en cours') {
    //   //       //   this.currentStatus = 'Traitement en cours'
    //   //       //   this.currentColor = 'warning'
    //   //       // }
    //   //       // this.loadUser(this.etsDetails.user_id)
    //   //     }
    //   //   })
    //   //   .catch((err) => {
    //   //     this.isFetchingEtsDetails = false
    //   //   })
    //   this.isFetchingEtsDetails = false
    // },

    loadUser(user_id) {
      let mail = ''
      this.action_getUser(user_id).then((res) => {
        if (res.status === 200) {
          console.log('res', res)
          mail = res.data.data.email
          this.etsDetails.email_etablissement = mail
          console.log('email', mail)
          console.log(this.etsDetails.email_etablissement)
          this.isFetchingEtsDetails = false
        }
      })
    },

    onEtsValidationSuccess() {
      this.etsDetails = { ...this.etsDetails, isVerified: 'true' }
    },
    onEtsRejectionSuccess(evt) {
      // this.$router.push({ name: "etablissements-list" });
    },
  },
}
</script>

<style lang="scss" scoped></style>
